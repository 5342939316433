<template>
  <div>
    <div class="container">
      <b-form class="form" @submit.stop.prevent="submit">
        <div
          id="kt_subheader"
          class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid"
        >
          <div
            class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
          >
            <!--begin::Info-->
            <div class="d-flex align-items-center flex-wrap mr-2">
              <!--begin::Page Title-->
              <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
                Build your Partner Application
              </h5>
              <!--end::Page Title-->
              <!--begin::Action-->
              <div
                class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
              ></div>

              <span class="text-muted font-weight-bold mr-4"> </span>
              <div
                class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
              ></div>

              <!--end::Action-->
            </div>

            <div class="d-flex align-items-center">
              <button type="submit" class="btn btn-success mr-2">
                Submit application
              </button>
            </div>
            <!--end::Info-->
          </div>
        </div>

        <div class="card mt-7">
          <div class="card-header">
            <h5 class="card-title">Build your Application</h5>
          </div>

          <div class="card-body">
            <div>
              <b-card bg-variant="light">
                <b-form-group label="Question 1:">
                  <b-form-input v-model="question1" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    ref="answer1"
                    v-model="answer1"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>

              <b-card v-if="question2 != ''" bg-variant="light" class="mt-5">
                <b-form-group label="Question 2:">
                  <b-form-input v-model="question2" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    v-model="answer2"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>

              <b-card v-if="question3 != ''" bg-variant="light" class="mt-5">
                <b-form-group label="Question 3:">
                  <b-form-input v-model="question3" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    v-model="answer3"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>

              <b-card v-if="question4 != ''" bg-variant="light" class="mt-5">
                <b-form-group label="Question 4:">
                  <b-form-input v-model="question4" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    v-model="answer4"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>

              <b-card v-if="question5 != ''" bg-variant="light" class="mt-5">
                <b-form-group label="Question 5:">
                  <b-form-input v-model="question5" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    v-model="answer5"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>

              <b-card v-if="question6 != ''" bg-variant="light" class="mt-5">
                <b-form-group label="Question 6:">
                  <b-form-input v-model="question6" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    v-model="answer6"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>

              <b-card v-if="question7 != ''" bg-variant="light" class="mt-5">
                <b-form-group label="Question 7:">
                  <b-form-input v-model="question7" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    v-model="answer7"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>

              <b-card v-if="question8 != ''" bg-variant="light" class="mt-5">
                <b-form-group label="Question 8:">
                  <b-form-input v-model="question8" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    v-model="answer8"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>

              <b-card v-if="question9 != ''" bg-variant="light" class="mt-5">
                <b-form-group label="Question 9:">
                  <b-form-input v-model="question9" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    v-model="answer9"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>

              <b-card v-if="question10 != ''" bg-variant="light" class="mt-5">
                <b-form-group label="Question 10:">
                  <b-form-input v-model="question10" disabled></b-form-input>
                </b-form-group>

                <b-form-group label="Answer:">
                  <b-form-textarea
                    v-model="answer10"
                    rows="3"
                    required
                    placeholder="Enter answer"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>
            </div>
            <div class="my-2">
              <!-- <v-btn color="primary" fab small dark @click="addQuestion">
            <v-icon>mdi-pencil</v-icon> 
          </v-btn> limit 10 -->
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      question6: "",
      question7: "",
      question8: "",
      question9: "",
      question10: "",
      questionId: null,
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
      answer6: "",
      answer7: "",
      answer8: "",
      answer9: "",
      answer10: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.getCompanyInfo({
        id: this.$route.params.id,
      }).then((res) => {
        if (res.data.question != null) {
          this.questionId = res.data.question.id;
          this.getQuestionInfo({
            id: this.questionId,
          }).then((questionRes) => {
            this.question1 = questionRes.data.question1;
            this.question2 = questionRes.data.question2;
            this.question3 = questionRes.data.question3;
            this.question4 = questionRes.data.question4;
            this.question5 = questionRes.data.question5;
            this.question6 = questionRes.data.question6;
            this.question7 = questionRes.data.question7;
            this.question8 = questionRes.data.question8;
            this.question9 = questionRes.data.question9;
            this.question10 = questionRes.data.question10;
          });
        }
      });
    }, 1000);
  },
  methods: {
    ...mapActions([
      "submitAnswer",
      "submitApplication",
      "getCompanyInfo",
      "getQuestionInfo",
    ]),
    submit(e) {
      e.preventDefault();
      if (this.answer1 == "") {
        this.$refs.answer1.focus();
        this.$bvToast.toast("Empty answer!", {
          title: "Error",
          variant: "danger",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
        });
        return;
      } else {
        this.submitAnswer({
          question: this.questionId,
          answer1: this.answer1,
          answer2: this.answer2,
          answer3: this.answer3,
          answer4: this.answer4,
          answer5: this.answer5,
          answer6: this.answer6,
          answer7: this.answer7,
          answer8: this.answer8,
          answer9: this.answer9,
          answer10: this.answer10,
        })
          .then((res) => {
            if (this.$store.state.auth.user.company_relation != null) {
              this.submitApplication({
                supplier_company: this.$route.params.id,
                seller_company: this.$store.state.auth.user.company_relation,
                status: "pending",
                answer: res.data.id,
              })
                .then(() => {
                  this.$router.push("/buyer/applications");
                  Swal.fire({
                    title: "",
                    text: "The application has been successfully submitted!",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary",
                  });
                })
                .catch(() => {
                  this.$bvToast.toast("An Error Occured!", {
                    title: "Error",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    solid: true,
                    appendToast: true,
                  });
                });
            }
          })
          .catch(() => {
            this.$bvToast.toast("An Error Occured!", {
              title: "Error",
              variant: "danger",
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: true,
            });
          });
      }
    },
  },
};
</script>
